import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { mr } from 'assets/css/globalCSS';
import { modalContainer } from './styles';

const ConfirmationPopup = ({ isModalVisible, handleOk, handleCancel, text, btnText = 'delete', confirmDisabled }) => {
  const { getText } = useTranslations();

  return (
    <Modal
      open={isModalVisible}
      zIndex={1100}
      onCancel={handleCancel}
      className={modalContainer}
      footer={null}
      centered
    >
      <h4 className="confirmation-modal-text">{text}</h4>
      <div className="confirmation-modal-buttons">
        <Button type="danger" small onClick={handleOk} disabled={confirmDisabled} className={mr({ lg: 10 })}>
          {getText(btnText)}
        </Button>
        <Button type="secondary" small onClick={handleCancel}>
          {getText('cancel')}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmationPopup.propTypes = {
  isModalVisible: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  text: PropTypes.string,
  btnText: PropTypes.string,
  confirmDisabled: PropTypes.bool,
};

export default ConfirmationPopup;
