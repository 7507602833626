import React from 'react';
import { Route, Switch } from 'react-router-dom';
// General
import InsufficientRights from 'screens/Public/errorPages/InsufficientRights';
import { useUser } from 'stores';
import { Routes } from 'enums';

// Private
import {
  MyProfile,
  // REMOVE TAG: Hide Coupons Details
  // CouponDetails,
  RequestRefundForm,
  MerchantOffice,
  Notifications,
  ShoppingCart,
  BusinessAccountForm,
  BusinessAddressForm,
  DeliveryAddressForm,
  StartChat,
  LeaveReviewForm,
  AddDealPage,
  OngoingChat,
  BankDetails,
  OpenMaterial,
  ShoppingCartCheckout,
  CreatePromoCode,
} from './Private';

// Public
import {
  NotFound,
  HomePage,
  DealDetailsPage,
  AllReviewsPage,
  AllConnectedDeals,
  DealListingPage,
  MerchantPublicPage,
  NearbyMerchants,
  PrivacyPolicy,
  TermsAndConditional,
  BrandGuidePage,
  OurGlobalStructure,
  GuestOrderDetailsPage,
  MerchantList,
} from './Public';

const Routing = () => {
  const { isAuthenticated, isMerchant } = useUser();

  return (
    <Switch>
      {isAuthenticated && <Route path={Routes.myProfile} component={MyProfile} />}
      {/* REMOVE TAG: Hide Coupons Details
      {isAuthenticated && <Route path={Routes.couponDetails} component={CouponDetails} />} */}
      {isAuthenticated && <Route path={Routes.requestRefund} component={RequestRefundForm} />}
      {isAuthenticated && <Route exact path={Routes.addBusinessAccount} component={BusinessAccountForm} />}
      {isAuthenticated && <Route exact path={Routes.notifications} component={Notifications} />}
      {isAuthenticated && <Route exact path={Routes.addDeliveryAddress} component={DeliveryAddressForm} />}
      {isAuthenticated && <Route exact path={Routes.editDeliveryAddress} component={DeliveryAddressForm} />}
      {isAuthenticated && <Route exact path={Routes.leaveReviewPage} component={LeaveReviewForm} />}
      {isAuthenticated && <Route exact path={Routes.editReviewPage} component={LeaveReviewForm} />}
      {isAuthenticated && <Route exact path={Routes.disputeReviewPage} component={LeaveReviewForm} />}
      {isAuthenticated && <Route exact path={Routes.startChat} component={StartChat} />}
      {isAuthenticated && <Route exact path={Routes.ongoingChat} component={OngoingChat} />}
      {isAuthenticated && <Route exact path={Routes.shoppingCart} component={ShoppingCart} />}
      {isAuthenticated && <Route path={Routes.shoppingCartCheckout} component={ShoppingCartCheckout} />}
      {isAuthenticated && <Route path={Routes.guestOrdersDetails} component={GuestOrderDetailsPage} />}
      {isAuthenticated && isMerchant && <Route path={Routes.merchantOffice} component={MerchantOffice} />}
      {isAuthenticated && isMerchant && <Route path={Routes.editDeal} component={AddDealPage} />}
      {isAuthenticated && isMerchant && <Route path={Routes.addDealPage} component={AddDealPage} />}
      {isAuthenticated && isMerchant && <Route path={Routes.addBusinessAddress} component={BusinessAddressForm} />}
      {isAuthenticated && isMerchant && <Route path={Routes.editBusinessAddress} component={BusinessAddressForm} />}
      {isAuthenticated && isMerchant && <Route path={Routes.bankDetails} component={BankDetails} />}
      {isAuthenticated && isMerchant && <Route path={Routes.brandMaterial} component={OpenMaterial} />}
      {isAuthenticated && isMerchant && <Route path={Routes.newPromoCode} component={CreatePromoCode} />}
      <Route path={Routes.nearbyMerchants} component={NearbyMerchants} />
      <Route exact path={Routes.dealPreviewPage} component={DealDetailsPage} />
      <Route exact path={Routes.dealDetailsPage} component={DealDetailsPage} />
      <Route exact path={Routes.editBusinessAccount} component={BusinessAccountForm} />
      <Route exact path={Routes.dealReviewsPage} component={AllReviewsPage} />
      <Route exact path={Routes.dealConnectedDealsPage} component={AllConnectedDeals} />
      <Route exact path={Routes.dealListingPage} component={DealListingPage} />
      <Route path={Routes.publicMerchant} component={MerchantPublicPage} />
      <Route exact path={Routes.insufficientRights} component={InsufficientRights} />
      <Route exact path={Routes.privacyPolicy} component={PrivacyPolicy} />
      <Route exact path={Routes.termsAndConditional} component={TermsAndConditional} />
      <Route exact path={Routes.homePage} component={HomePage} />
      <Route exact path={Routes.privacyPolicy} component={PrivacyPolicy} />
      <Route exact path={Routes.termsAndConditional} component={TermsAndConditional} />
      <Route exact path={Routes.brandGuide} component={BrandGuidePage} />
      <Route exact path={Routes.ourGlobalStructure} component={OurGlobalStructure} />
      <Route exact path={Routes.merchantList} component={MerchantList} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Routing;
