import React, { useState, useRef } from 'react';
import { useTheme } from 'emotion-theming';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { softLogout } from '@oneecosystem/authenticate';
import { useCurrencies, useLocations } from '@oneecosystem/dealshaker-core';
import { Link } from 'react-router-dom';
import { Popover, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { isMobile } from 'react-device-detect';
import { SearchInput } from 'components/ui';
import { Button, Pagination } from 'components/UIExternal';
import { PriceLabel } from 'components/shared';
import { useUser } from 'stores';
import { Routes } from 'enums';
import { getAllAccounts } from 'services';
import useError from 'services/errorHandling/useError';
import goToMerchant from 'assets/images/goToMerchantImage.svg';
import { blackTextLink } from 'assets/css/globalCSS';
import {
  userProfileImage,
  miniUserProfileMenu,
  bottomProfile,
  accountId,
  popoverWrapper,
  yourAccountsTitle,
  usernames,
  walletAccountDialog,
  accountsDialogMenu,
  accountWrapper,
  accountAndSwitchButton,
  scrollContainer,
  noMatch,
  iconLinks,
  badgeContainer,
  wallet,
  addDealMiniUserStyle,
} from './styles';

const filterKeys = {
  search: 'searchText',
};

const UserMiniProfile = () => {
  useCurrencies(currenciesState => currenciesState?.selectedCurrency);
  const theme = useTheme();
  const { getText } = useTranslations();
  const { setError } = useError();
  const [showAccountsContent, setShowAccountsContent] = useState(false);
  const [accData, setAccData] = useState(null);
  const { isMerchant, userInfo, isDisabled, currentAccount, changeCurrentAccount, userMiniProfileRef } = useUser();
  const geoLocation = useLocations(locationsState => locationsState.geoLocation);
  const [visible, setVisible] = useState(false);
  const paginationRef = useRef();
  const cryptoWalletBalance = account =>
    (geoLocation?.isCryptoRestricted ? account?.oespWalletBalance : account?.coinWalletBalance) || 0;

  const fetchData = async options => {
    const [res, err] = await getAllAccounts(options);
    err ? setError(err) : setAccData(res?.items);
    return res;
  };

  const toggleVisible = () => setVisible(!visible);

  const contentAccounts = (
    <>
      <div className={scrollContainer(theme)}>
        <Button
          type="link"
          small
          onClick={() => {
            setShowAccountsContent(false);
            paginationRef.current?.applyFilters({ [filterKeys.search]: null });
          }}
          leftIcon={<FeatherIcon icon="arrow-left" size={18} />}
        >
          {getText('back')}
        </Button>
        <p className={yourAccountsTitle}>{getText('yourAccounts')}</p>
        <SearchInput
          onPressEnter={val => paginationRef.current?.applyFilters({ [filterKeys.search]: val })}
          placeholder={getText('searchForAccounts')}
        />
      </div>
      <div className={accountsDialogMenu(theme)}>
        {isNil(accData) ? <Spin /> : !accData?.length && <p className={noMatch}>{getText('noMatchForYourSearch')}</p>}

        {accData?.map(account => (
          <div
            key={account.id}
            tabIndex={0}
            role="button"
            onClick={() => {
              changeCurrentAccount(account);
              setShowAccountsContent(false);
              paginationRef.current?.applyFilters({ [filterKeys.search]: null });
            }}
            className={`${accountWrapper(theme)} `}
          >
            <p className={usernames}> {account.name}</p>
            <p className={accountId}>#{account.externalId}</p>
            <div className={walletAccountDialog(theme)}>
              <span className="icon-Wallet" />
              <p style={{ fontSize: 14 }}>
                <PriceLabel fiat={account?.cashWalletBalance || 0} ignoreEmpty /> |{' '}
                <PriceLabel crypto={cryptoWalletBalance(account)} ignoreEmpty />
              </p>
            </div>
          </div>
        ))}

        <Pagination ref={paginationRef} onChange={fetchData} pageSize={4} filterKeys={filterKeys} justify="center" />
      </div>
    </>
  );

  const contentSingleAccount = (
    <>
      {currentAccount ? (
        <>
          <div className={miniUserProfileMenu(theme)}>
            <div className={accountAndSwitchButton}>
              {userInfo && userInfo?.defaultAccount && <label className={usernames}>{currentAccount.name}</label>}
              <Button
                type="link"
                small
                className={blackTextLink(theme)}
                onClick={() => {
                  setShowAccountsContent(true);
                  paginationRef.current?.applyFilters({ [filterKeys.search]: null });
                }}
              >
                <FeatherIcon icon="repeat" size={16} />
              </Button>
            </div>
            <p className={accountId}>#{currentAccount.externalId}</p>
            <div className={wallet(theme)}>
              <span className="icon-Wallet" />
              <p>
                <PriceLabel fiat={currentAccount?.cashWalletBalance || 0} ignoreEmpty /> |{' '}
                <PriceLabel crypto={cryptoWalletBalance(currentAccount)} ignoreEmpty />
              </p>
            </div>
            {!isDisabled && (
              <>
                <div className="flex space-between fullWidth">
                  <Button
                    type="link"
                    small
                    linkTo={`${Routes.myProfile}${Routes.orders}`}
                    onClick={toggleVisible}
                    className={iconLinks(theme)}
                  >
                    <span className="icon-Coupons" />
                    {getText('orders')}
                  </Button>
                </div>
                <Button
                  type="link"
                  small
                  linkTo={`${Routes.myProfile}${Routes.profileInformation}`}
                  onClick={toggleVisible}
                  className={iconLinks(theme)}
                >
                  <FeatherIcon icon="user" size={15} strokeWidth={1} />
                  {getText('myProfile')}
                </Button>
                <Button
                  type="link"
                  small
                  linkTo={`${Routes.myProfile}${Routes.wishList}`}
                  className={iconLinks(theme)}
                  onClick={toggleVisible}
                >
                  <FeatherIcon icon="heart" size={15} strokeWidth={1} />
                  {getText('myWishList')}
                </Button>
              </>
            )}
          </div>
          <div className={bottomProfile}>
            {!isDisabled && (
              <>
                <Link
                  to={isMerchant ? `${Routes.merchantOffice}${Routes.myDeals}` : Routes.addBusinessAccount}
                  onClick={toggleVisible}
                >
                  <img src={goToMerchant} alt="Logo" />
                </Link>
                {isMerchant ? (
                  <>
                    <Button type="link" linkTo={`${Routes.merchantOffice}${Routes.myDeals}`} onClick={toggleVisible}>
                      {getText('goToMerchantOffice')}
                    </Button>
                    {isMobile && (
                      <Button
                        type="info"
                        className={addDealMiniUserStyle}
                        small
                        linkTo={Routes.addDealPage}
                        onClick={toggleVisible}
                      >
                        {getText('addDeal')}
                      </Button>
                    )}
                  </>
                ) : (
                  <Button type="link" linkTo={Routes.addBusinessAccount} onClick={toggleVisible}>
                    {getText('becomeMerchant')}
                  </Button>
                )}
              </>
            )}

            <Button type="default" className="logout-btn" fullWidth onClick={softLogout}>
              {getText('logOut')}
            </Button>
          </div>
        </>
      ) : (
        <Spin />
      )}
    </>
  );

  return (
    <Popover
      ref={userMiniProfileRef}
      content={showAccountsContent ? contentAccounts : contentSingleAccount}
      overlayClassName={popoverWrapper(theme)}
      placement="bottomRight"
      open={visible}
      onOpenChange={toggleVisible}
      trigger="click"
    >
      <div className={badgeContainer}>
        {userInfo?.avatarUrl ? (
          <img src={userInfo?.avatarUrl} className={userProfileImage} alt="prop" />
        ) : (
          <FeatherIcon icon="user" size={24} stroke={theme.white} strokeWidth={1} />
        )}
      </div>
    </Popover>
  );
};

export default UserMiniProfile;
